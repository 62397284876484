import { css } from "goober";
import { mobileLarge } from "clutch/src/Style/style.mjs";

export const containerCss = () => css`
  background-color: var(--shade8);
  border: 1px solid var(--shade3-50);
  border-radius: var(--br-lg);

  display: flex;
  flex-direction: row;
  justify-items: flex-start;

  & > * {
    display: flex;
  }
`;

export const tabContainerCss = () => css`
  &:not(:last-child) {
    border-right: 1px solid var(--shade3-50);
  }

  &:hover {
    background-color: var(--shade6);
  }

  &.active {
    > label {
      color: var(--shade0);
    }
    background-color: var(--shade6);
  }
`;

export const tabLabelCss = () => css`
  padding: var(--sp-1);
  color: var(--shade2);
  cursor: pointer;

  .active & {
    color: var(--shade0);
  }

  .toggle-icon {
    width: var(--sp-6);
    height: var(--sp-6);
  }

  ${mobileLarge} {
    .toggle-description {
      display: none;
    }
  }
`;
