import React from "react";
import { css } from "goober";

import { classNames } from "@/util/class-names.mjs";

const cssContainer = () => css`
  & {
    display: flex;
    flex-direction: "column";
  }
  > * {
    display: flex;
  }
`;

const cssTabContainer = () => css`
  & {
    margin: auto 0;
    padding: 0 var(--sp-3);
    height: var(--sp-13);
    align-items: center;
    width: fit-content;
  }

  &.active {
    border-bottom: 2px solid var(--primary);
    font-weight: 700;
    > label {
      color: var(--shade0);
    }
  }
`;

const cssTabInput = () => css`
  & {
    display: none;
  }
`;

const cssTabLabel = () => css`
  & {
    color: var(--shade2);
  }
`;

export interface TabsProps<T extends string> {
  tabOptions: { name: T; content?: React.ReactNode }[];
  onChange: (index: number) => void;
  activeTab: number;
  label?: string;
  classes?: {
    containerClass?: string;
    tabContainerClass?: () => string;
    tabInputClass?: string;
    tabLabelClass?: string;
  };
}

export function Tabs<T extends string>({
  tabOptions,
  onChange,
  activeTab,
  classes,
  label,
}: TabsProps<T>) {
  const {
    containerClass: containerClassOverride,
    tabContainerClass: tabContainerClassOverride,
    tabInputClass: tabInputClassOverride,
    tabLabelClass: tabLabelClassOverride,
  } = classes || {};

  const tabs = tabOptions.map((option, index) => {
    return (
      <div
        key={option.name}
        {...classNames(
          tabContainerClassOverride?.() || cssTabContainer(),
          activeTab === index && "active",
        )}
      >
        <input
          className={tabInputClassOverride || cssTabInput()}
          type="radio"
          id={option.name}
          name="tab_option"
          value={index}
          checked={activeTab === index}
          onChange={() => onChange(index)}
        />
        <label
          className={tabLabelClassOverride || cssTabLabel()}
          htmlFor={option.name}
        >
          {option.content || option.name}
        </label>
      </div>
    );
  });

  return (
    <div
      className={containerClassOverride || cssContainer()}
      role="radiogroup"
      aria-label={label}
    >
      {tabs}
    </div>
  );
}
